import styled from 'styled-components';
import React from 'react';

import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Paper } from 'atoms';
import { Heading, Paragraph } from 'Typography';
import { useTranslation } from 'react-i18next';
import LinkButton from '../LinkButton/LinkButton';

const Card = styled(Paper)`
  padding: 0 0 0 1.67rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-auto-rows: min-content;
  grid-template-areas:
    'header button'
    'paragraph button';
`;

const ClassCard = ({ classData, ...args }) => {
  const { attributes, id } = classData;
  const { name, graduationYear, inactive } = attributes;
  const { t } = useTranslation();
  return (
    <Card elevation="1" {...args}>
      <Heading type="h5" area="header" padding="1.8rem 0 0 0">
        {`Klasse ${name}`}
      </Heading>
      <Paragraph area="paragraph" padding="0 0 1.8rem 0">
        {`Abschlussjahr ${graduationYear || '?'}`}
      </Paragraph>
      <LinkButton.Open area="button" primary to={`/${t('class')}/${id}`} disabled={inactive}>
        <FAIcon icon={faArrowRight} />
      </LinkButton.Open>
    </Card>
  );
};

export default ClassCard;
